import { render, staticRenderFns } from "./all-archive.vue?vue&type=template&id=54561dd7&scoped=true&"
import script from "./all-archive.vue?vue&type=script&lang=ts&data-die=true&"
export * from "./all-archive.vue?vue&type=script&lang=ts&data-die=true&"
import style0 from "./all-archive.vue?vue&type=style&index=0&id=54561dd7&lang=scss&scoped=true&"
import style1 from "./transition.scss?vue&type=style&index=1&id=54561dd7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54561dd7",
  null
  
)

export default component.exports