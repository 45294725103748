













































































import { defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import { req } from '@/api';
import { parseTime } from '@/public/methods';

let isMounted = false;

export default defineComponent({
	props: ['data'],
	setup(props: any, { root }: any) {
		onMounted(() => {
			isMounted = true;
		});
		onBeforeUnmount(() => {
			isMounted = false;
		});
		const { $message } = root;
		const t = root.$t.bind(root);
		const archiveData = ref<any>({});
		const thisYear: Ref<number> = ref(new Date().getFullYear());
		const thisMonth: Ref<number> = ref(new Date().getMonth() + 1);
		const openObj: Ref<any> = ref({
			[thisYear.value]: true,
			[thisYear.value * 100 + thisMonth.value]: true,
		}); //这里是打开的内容
		const reqOpen = (value: number): void => {
			//加载
			req('BLOG_ARTICLE_ARCHIVE_SIMP', { month: '' + value })
				.then((data: any) => {
					if (isMounted) {
						//console.log(data);
						archiveData.value = {
							...archiveData.value,
							[value]: data,
						};
					}
				})
				.catch(error => {
					//$message.error(error);
					archiveData.value = {
						...archiveData.value,
						[value]: '' + error,
					};
				});
		};
		reqOpen(thisYear.value * 100 + thisMonth.value); //首次加载
		const toggleOpen = (value: number): void => {
			if (!openObj.value[value] && value / 100000 > 1) {
				reqOpen(value);
			}
			openObj.value = {
				...openObj.value,
				[value]: !openObj.value[value],
			};
		};
		const generateMonth = (year: number): number[] => {
			//return new Array(year === thisYear.value ? thisMonth.value : 12)
			//    .map((item, index) => index + 1)
			//    .reverse();
			return Array.from({ length: year === thisYear.value ? thisMonth.value : 12 }, (item, index) => index + 1).reverse();
		};
		return {
			thisYear,
			thisMonth,
			openObj,
			toggleOpen,
			generateMonth,
			archiveData,
			parseTime,
			t,
		};
	},
});
