import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Card from '@/components/Card';

import { Skeleton } from '@/components/UI';

import AllArchive from './all-archive';

interface TDate {
    y: number;
    m: number;
    d: number;
}
function getDate(value: number | string): TDate {
    const res: TDate = {
        y: -1,
        m: -1,
        d: -1,
    };
    value = '' + value;
    res.y = isNaN(parseInt(value.substring(0, 4)))
        ? -1
        : parseInt(value.substring(0, 4));
    res.m = isNaN(parseInt(value.substring(4, 6)))
        ? -1
        : parseInt(value.substring(4, 6));
    res.d = isNaN(parseInt(value.substring(6, 8)))
        ? -1
        : parseInt(value.substring(6, 8));

    return res;
}

export default defineComponent({
    components: { Card, Skeleton, AllArchive },
    setup(props: any, { root }: any) {
        const { $route, $message } = root;
        const isLoading: Ref<boolean> = ref(true);
        const isError: Ref<boolean> = ref(false);
        const isShowAll: Ref<boolean> = ref(false);
        const allData: Ref<any> = ref([]);
        const archive = ref({});
        onMounted(() => {
            if ($route?.params?.date) {
                isShowAll.value = false;
                const date = getDate($route.params.date);
                if (
                    1900 < date.y &&
                    date.y < 2038 &&
                    0 < date.m &&
                    date.m <= 12 &&
                    (date.d === -1 || (0 < date.d && date.d <= 31))
                ) {
                    isLoading.value = false;
                    archive.value = { ...date };
                } else {
                    isError.value = true;
                }
            } else {
                //如果是显示全部的
                isShowAll.value = true;
                let betweenYear = [2022, 1990];
                if (betweenYear[0] < betweenYear[1]) {
                    betweenYear = [betweenYear[1], betweenYear[0]];
                }
                for (let i = betweenYear[0]; i >= betweenYear[1]; i--) {
                    allData.value = [
                        ...allData.value,
                        {
                            year: i,
                            count: 0,
                            children: [],
                        },
                    ];
                }
            }
        });
        return { isLoading, isError, isShowAll, allData, archive };
    },
});
